/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

/* App container */
.app-container {
  text-align: center;
  padding: 20px;
}

/* Title styling */
.app-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}
.flashcard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.shuffle-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.shuffle-button:hover {
  background-color: #45a049;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.shuffle-button:active {
  background-color: #3e8e41;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

.FlashcardWrapper__item--content{
  display: flex;
  align-items: center;
}